import { forwardRef } from 'react';
import { CSVLink } from 'react-csv';
import { AccountantTSView, MonthlyTimesheetToExport } from '../../../../types';
import { useCombinedCSVTemplate } from '../../hooks/useCombinedCSVTemplate';
import { useSeparateCSVTemplate } from '../../hooks/useSeparateCSVTemplate';

interface CSVLinkCombinedProps {
  monthlyTS: MonthlyTimesheetToExport[];
  showDataBy: AccountantTSView;
}

export const CSVLinkCombined = forwardRef<HTMLAnchorElement, CSVLinkCombinedProps>(
  ({ monthlyTS, showDataBy }, ref) => {
    const combinedData = useCombinedCSVTemplate(
      monthlyTS,
      showDataBy
    );

    return <CSVLink data={combinedData} filename='Combined-Timesheets.csv' ref={ref} />;
  }
);

CSVLinkCombined.displayName = 'CSVLinkCombined';

interface CSVLinkSeparateProps {
  monthlyTS: MonthlyTimesheetToExport;
  showDataBy: AccountantTSView;
}

export const CSVLinkSeparate = forwardRef<HTMLAnchorElement, CSVLinkSeparateProps>(
  ({ monthlyTS, showDataBy }, ref) => {
    const data = useSeparateCSVTemplate(monthlyTS, showDataBy);

    return <CSVLink data={data} filename={`Timesheets-${monthlyTS.name}.csv`} ref={ref} />;
  }
);

CSVLinkSeparate.displayName = 'CSVLinkSeparate';

import { useMemo } from 'react';
import { AccountantTSView, MonthlyTimesheetToExport } from '../../../types';
import {
  generateBulkCSVTemplate,
  generateMonthCSVTemplate,
} from '../../../utils/csvExportTimesheet';

export const useCombinedCSVTemplate = (
  timesheet: MonthlyTimesheetToExport[],
  selectedTab: AccountantTSView
) => {
  const csvMonthTemplateData = useMemo(() => {
    return generateMonthCSVTemplate(timesheet);
  }, [timesheet]);

  const csvTemplateData = useMemo(() => {
    return timesheet.flatMap((ts) => generateBulkCSVTemplate(ts, selectedTab));
  }, [selectedTab, timesheet]);

  return selectedTab === AccountantTSView.month ? csvMonthTemplateData : csvTemplateData;
};

import { Box, Button, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { MonthlyTimesheet, TimesheetStatus, TimesheetStatusLabel } from '../../../types';
import { getAccountantTsStatusLabel } from '../../../utils/tsMappers';

type Props = {
  timesheet?: MonthlyTimesheet;
};

export function AccountantStatusCell({ timesheet }: Props) {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const statusLabel = useMemo(() => {
    return getAccountantTsStatusLabel(timesheet?.status as TimesheetStatus);
  }, [timesheet?.status]);

  const isTSDisabled =
    statusLabel === TimesheetStatusLabel.ISSUED || statusLabel === TimesheetStatusLabel.REJECTED;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const yearQueryParam = params.get('year');

  const redirectToSingleTs = () => {
    const queryParam = yearQueryParam ? `?year=${yearQueryParam}` : '';
    navigate(`/${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}/${timesheet?.id}${queryParam}`);
  };

  const backgroundStyle = useMemo(() => {
    if (statusLabel === TimesheetStatusLabel.SUBMITTED) return colors.surfaceAccent.default;
    else if (isTSDisabled) return colors.surfaceInteraction.selectedLight;
    else if (timesheet?.status === TimesheetStatus.CLOSED) return 'transparent';
    return colors.surfaceStatus.active;
  }, [timesheet, colors, statusLabel, isTSDisabled]);

  const colorStyle = useMemo(() => {
    if (statusLabel === TimesheetStatusLabel.SUBMITTED) return colors.textAccent.inverse;
    else if (isTSDisabled) return colors.icon.disabled;
    return colors.textStatus.active;
  }, [colors, statusLabel, isTSDisabled]);

  const cellLabel = useMemo(() => {
    if (statusLabel === TimesheetStatusLabel.SUBMITTED) return 'Review';
    return statusLabel;
  }, [statusLabel]);

  if(!timesheet) return <></>;

  return (
    <Box display={'flex'} justifyContent={'start'}>
      <Button
        variant='text'
        disabled={isTSDisabled}
        style={{
          background: backgroundStyle,
          color: colorStyle,
        }}
        onClick={redirectToSingleTs}
      >
        {cellLabel}
      </Button>
    </Box>
  );
}

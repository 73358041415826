import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { useTheme } from '@mui/material';
import { TableV1 } from '../../../../components/Table/TableV1';
import {
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useParams } from 'react-router';
import { TableSkeletonLoader } from '../../../../components/SkeletonLoader/Table.SkeletonLoader';
import { SingleTimesheetHeader } from './SingleTimesheetHeader';
import { useMonthlySingleTimesheet } from '../../../../queries/hooks/useMonthlySingleTimesheet';
import { useDealOwnersSingleTableColumns } from '../../hooks/useDealOwnersSingleTableColumns';

import { userState } from '../../../../state/UIState';

export function DealOwnerSingleTimesheetTable() {
  const { colors } = useTheme();
  const { id } = useParams();
  const user = useAtomValue(userState);

  const { data: singleTimesheet, isLoading: dataIsLoading } = useMonthlySingleTimesheet({
    id: Number(id),
  });

  const dealOwnerDeals = useMemo(() => {
    return singleTimesheet?.timesheetRows.filter((row) =>
      user?.dealOwnerDeals?.some((deal) => deal.id === row.dealId)
    );
  }, [singleTimesheet?.timesheetRows, user?.dealOwnerDeals]);

  const columns = useDealOwnersSingleTableColumns();

  const table = useReactTable({
    data: dealOwnerDeals ?? [],
    columns,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  if (dataIsLoading || !singleTimesheet) return <TableSkeletonLoader />;

  return (
    <>
      <SingleTimesheetHeader />
      <TableV1
        hasFooter
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </>
  );
}

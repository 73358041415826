import { useMemo } from "react";
import { WeeklyTimesheet, WeekdayTimesheetTableData, MonthlyTimesheetRow } from "../../../types";
import { calcTotalWeekdayPerDeal } from "./useTimesheetCalculations";

export function useWeekdayTableData (weeklyTimesheet: WeeklyTimesheet[], monthlyTimesheetRow: MonthlyTimesheetRow[]) {
    const tableData: WeekdayTimesheetTableData[] = useMemo(() => {
        return weeklyTimesheet[0].timesheetRows.map((row, i) => {
            return {
                id: row.id,
                dealId: row.dealId,
                name: row.name,
                monday: calcTotalWeekdayPerDeal(i, 'monday', weeklyTimesheet),
                tuesday: calcTotalWeekdayPerDeal(i, 'tuesday', weeklyTimesheet),
                wednesday: calcTotalWeekdayPerDeal(i, 'wednesday', weeklyTimesheet),
                thursday: calcTotalWeekdayPerDeal(i, 'thursday', weeklyTimesheet),
                friday: calcTotalWeekdayPerDeal(i, 'friday', weeklyTimesheet),
                avg: Number(monthlyTimesheetRow[i].avg.toFixed(2)),
            }
        })
    }, [weeklyTimesheet, monthlyTimesheetRow])

    return tableData;
}
import { Button, styled, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import { FormField } from '../../../../components/FormField/FormField';
import { Multiselect } from '../../../../components/Multiselect/Multiselect';
import { SingleSelect } from '../../../../components/SingleSelect/SingleSelect';
import { SelectItem, UserRole } from '../../../../types';
import { IUserFileds } from './UserModal';
import { UpdateUserSkeletonLoader } from '../../../../components/SkeletonLoader/UpdateUser.SkeletonLoader';
import { useUsers } from '../../../../queries/hooks/useUsers';
import { useDeals } from '../../../../queries/hooks/useDeals';
import { DatePicker } from '../../../../components/DatePicker/DatePicker';
import { addDaysToDate } from '../../../../utils/formatters';
import { Typography } from '../../../../components/Typography/Typography';
import { ReactComponent as USAFlag } from '../../../../assets/icons/usa-flag.svg';
import { ReactComponent as UKFlag } from '../../../../assets/icons/uk-flag.svg';
import { ConfirmModal } from '../../../../components/ConfirmModal/ConfirmModal';

interface OperatingPartnerFieldsProps {
  isOpManager?: boolean;
}

export function OperatingPartnerFields({ isOpManager = false }: OperatingPartnerFieldsProps) {
  const { colors } = useTheme();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | string>('');
  const { data: dealOptionsRaw, isLoading: dealOptionsLoading } = useDeals();
  const { isLoading: areUsersLoading, data: users } = useUsers();

  const { setFieldValue, values, touched, errors, setFieldTouched, initialValues } =
    useFormikContext<IUserFileds>();

  const handleError = (field) => (touched[field] ? errors[field] : '');

  const dealsOptions: SelectItem[] = useMemo(() => {
    if (!dealOptionsRaw) return [];
    return dealOptionsRaw?.map((deal) => {
      return {
        ...deal,
        id: deal.id,
        value: deal?.name || '',
      };
    });
  }, [dealOptionsRaw]);

  const proxyOptions = useMemo(() => {
    if (!users) return [];
    return users
      .filter((user) => user.role === UserRole.PROXY)
      .map((user) => ({
        id: user.id,
        value: user.name,
      }));
  }, [users]);

  const managersOptions = useMemo(() => {
    if (!users) return [];
    return users
      .filter((user) => user.role === UserRole.OP_MANAGER && user.email !== values.email)
      .map((user) => ({
        id: user.id,
        value: user.name,
      }));
  }, [users, values.email]);

  const labelStyle = {
    color: colors.text.caption,
  };

  const minDate = useMemo(() => {
    if (values.startDate) return addDaysToDate(values.startDate, 1).toISOString();
    return null;
  }, [values.startDate]);

  const maxDate = useMemo(() => {
    if (values.endDate) return addDaysToDate(values.endDate, -1).toISOString();
    return null;
  }, [values.endDate]);

  const loadingFieldsOptions = dealOptionsLoading || areUsersLoading;

  return loadingFieldsOptions ? (
    <UpdateUserSkeletonLoader />
  ) : (
    <>
      <FormField label={'Managers'} error={handleError('managers')} labelStyle={labelStyle}>
        <Multiselect
          listboxStyle={{ height: '195px' }}
          options={managersOptions}
          value={values?.managers as SelectItem[]}
          onChange={(_, managers) => {
            setFieldValue('managers', managers);
          }}
          onBlur={() => {
            setFieldTouched('managers');
          }}
          disablePortal
          fieldPlaceholder='Type to Search Managers'
        />
      </FormField>
      <DateWrapper>
        <FormField
          label={'Start Work Date'}
          error={handleError('startDate')}
          labelStyle={labelStyle}
        >
          <DatePicker
            clearable
            selectedDate={values.startDate ?? ''}
            setSelectedDate={(date) => {
              if (date) {
                setOpenConfirmModal(true);
                setSelectedDate(date);
              } else {
                setFieldValue('startDate', date);
              }
            }}
            placeholder='Start Date'
            maxDate={maxDate}
          />
        </FormField>

        <FormField label='End Work Date (Optional)' labelStyle={labelStyle}>
          <DatePicker
            clearable
            selectedDate={values.endDate ?? ''}
            setSelectedDate={(date) => {
              setFieldValue('endDate', date);
            }}
            placeholder='End Date'
            maxDate={null}
            minDate={minDate}
          />
        </FormField>
      </DateWrapper>
      <FormField label='Country' labelStyle={labelStyle} error={handleError('country')}>
        <CountryWrapper>
          <Button
            variant={values?.country === 'US' ? 'contained' : 'outlined'}
            onClick={() => {
              setFieldValue('country', 'US');
            }}
            style={{
              backgroundColor: values?.country === 'US' ? '' : 'transparent',
              borderRadius: '4px 0 0 4px',
            }}
          >
            <USAFlag />
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                marginLeft: '4px',
              }}
            >
              US
            </Typography>
          </Button>
          <Button
            variant={values?.country === 'UK' ? 'contained' : 'outlined'}
            onClick={() => {
              setFieldValue('country', 'UK');
            }}
            style={{
              backgroundColor: values?.country === 'UK' ? '' : 'transparent',
              borderRadius: '0 4px 4px 0',
            }}
          >
            <UKFlag />
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                marginLeft: '4px',
              }}
            >
              UK
            </Typography>
          </Button>
        </CountryWrapper>
      </FormField>
      <FormField
        label={'Deals'}
        error={handleError('operatingPartnerDeals')}
        labelStyle={labelStyle}
      >
        <Multiselect
          listboxStyle={{ height: '195px' }}
          options={dealsOptions}
          value={values?.operatingPartnerDeals as SelectItem[]}
          onChange={(_, deals) => {
            setFieldValue('operatingPartnerDeals', deals);
          }}
          onBlur={() => {
            setFieldTouched('operatingPartnerDeals');
          }}
          disablePortal
          fieldPlaceholder='Type to Search Deals'
        />
      </FormField>

      <FormField label={'Proxy (Optional)'} error={handleError('proxy')} labelStyle={labelStyle}>
        <SingleSelect
          listboxStyle={{ height: '195px' }}
          options={proxyOptions}
          value={values?.proxy}
          onChange={(_, option) => {
            setFieldValue('proxy', option);
          }}
          onBlur={() => {
            setFieldTouched('proxy');
          }}
          disablePortal
          fieldPlaceholder='Select proxy'
        />
      </FormField>

      {!isOpManager ? (
        <Button
          variant='outlined'
          style={{
            padding: '8px',
          }}
          onClick={() => {
            setFieldValue('role', UserRole.OP_MANAGER);
            setFieldValue('isOp', true);
            setFieldValue('isSelfApprovingEnabled', true);
          }}
        >
          Promote to Operating Partner Manager
        </Button>
      ) : (
        <Button
          variant='outlined'
          style={{
            padding: '8px',
          }}
          onClick={() => {
            setFieldValue('role', UserRole.OPERATING_PARTNER);
            setFieldValue('isOp', true);
          }}
        >
          Downgrade to Operating Partner
        </Button>
      )}

      <ConfirmModal
        open={openConfirmModal}
        title='Edit Start Work Date'
        note='Changing Start Work Date will result in deleting all Timesheets created before. Are you sure you want to do that?'
        onClose={() => {
          setFieldValue('startDate', initialValues.startDate);
          setOpenConfirmModal(false);
        }}
        onConfirm={() => {
          setFieldValue('startDate', selectedDate);
          setOpenConfirmModal(false);
        }}
      />
    </>
  );
}

const DateWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  gap: 12px;
  .MuiFormControl-root {
    width: 100%;
  }
`;

const CountryWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

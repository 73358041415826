import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { ModalComponent } from '../../../../components/ModalComponent/ModalComponent';
import { useMonthlyTimesheetsByTimeRange } from '../../../../queries/hooks/useMonthlyTimesheetsByTimeRange';
import { DealSkeletonLoading } from '../../../../components/SkeletonLoader/DealSkeletonLoading';
import { ExportTimesheetsForm } from './ExportTimesheetsForm';
import { useMonthlyTimesheetsToExport } from '../../../../queries/hooks/useMonthlyTimesheetsToExport';
import { CSVLinkCombined, CSVLinkSeparate } from './CSVExport';
import { AccountantTSView } from '../../../../types';

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export interface IExportMultiTSForm {
  selectedPeriod: {
    year: number;
    months: {
      [month: number]: boolean;
    };
  }[];
  showDataBy: AccountantTSView | null;
  exportSeparateCSVs: boolean;
  exportCombinedCSV: boolean;
}

const initialValues: IExportMultiTSForm = {
  selectedPeriod: [],
  showDataBy: null,
  exportSeparateCSVs: false,
  exportCombinedCSV: false,
};

export function ExportMultiTimesheetModal({ isOpen, onClose }: ModalProps) {
  const [timeRangeParams, setTimeRangeParams] = useState('');
  const [searchParams] = useSearchParams();
  const ids = searchParams.get('ids') ?? '';
  const [downloadCombined, setDownloadCombined] = useState(false);
  const csvCombinedRef = useRef<any>(null);
  const [downloadMultiple, setDownloadMultiple] = useState(false);
  const csvMultipleRef = useRef<any[]>([]);

  const {
    isLoading: isLoadingTSAvailability,
    data: availablePeriods,
    isSuccess,
  } = useMonthlyTimesheetsByTimeRange(ids);

  const { data: timesheets, isLoading: isLoadingTS } = useMonthlyTimesheetsToExport({
    userIds: ids,
    timeRange: timeRangeParams,
  });

  useEffect(() => {
    if (timesheets && downloadCombined && csvCombinedRef.current) {
      csvCombinedRef?.current.link.click();
      setDownloadCombined(false);
    } else if (timesheets && downloadMultiple && csvMultipleRef.current) {
      csvMultipleRef.current.forEach((ref) => {
        if (ref) {
          ref.link.click();
        }
        setDownloadMultiple(false);
      });
    }
  }, [downloadCombined, downloadMultiple, timesheets]);

  const handleExportClick = (exportSeparate: boolean) => {
    exportSeparate ? setDownloadMultiple(true) : setDownloadCombined(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values: IExportMultiTSForm) => {
        const queryParams = values.selectedPeriod
          .flatMap(({ year, months }) =>
            Object.entries(months)
              .filter(([_, value]) => value)
              .map(([month]) => `${month}-${year}`)
          )
          .join(',');
        setTimeRangeParams(queryParams);
        handleExportClick(values.exportSeparateCSVs);
      }}
    >
      {({ handleSubmit, values }) => {
        const isFormDisabled =
          values.selectedPeriod.every(({ months }) => Object.values(months).every((val) => !val)) ||
          !(values.exportCombinedCSV || values.exportSeparateCSVs);
        return (
          <Form>
            <ModalComponent
              isOpen={isOpen}
              onClose={onClose}
              actionButtonsProps={{
                isSubmitDisabled: isFormDisabled,
                onSubmit: handleSubmit,
                onCancel: onClose,
                submitText: isLoadingTS ? 'Exporting...' : 'Export CSV',
              }}
              title={`Export Timesheet`}
              top='80px'
            >
              <>
                {isLoadingTSAvailability && <DealSkeletonLoading />}
                {isSuccess && <ExportTimesheetsForm availablePeriods={availablePeriods} />}
                {downloadCombined && timesheets && values.showDataBy && (
                  <CSVLinkCombined
                    showDataBy={values.showDataBy}
                    monthlyTS={timesheets}
                    ref={csvCombinedRef}
                  />
                )}
                {downloadMultiple &&
                  values.showDataBy && 
                  timesheets
                    ?.filter((ts) => ts.timesheets.length)
                    .map((ts, i) => (
                      <CSVLinkSeparate
                        showDataBy={values.showDataBy!}
                        monthlyTS={ts}
                        ref={(el) => (csvMultipleRef.current[i] = el)}
                        key={i}
                      />
                    ))}
              </>
            </ModalComponent>
          </Form>
        );
      }}
    </Formik>
  );
}

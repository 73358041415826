import { TimesheetStatus, TimesheetStatusLabel, UserRole } from '../types';

export const mapTsStatuses: <T extends { status: TimesheetStatus | TimesheetStatusLabel }>(
  timesheets: T[],
  role: UserRole
) => T[] = (timesheets, role) => {
  return timesheets.map((ts) => {
    const mappedTs = { ...ts };
    switch (role) {
      case UserRole.OPERATING_PARTNER:
        mappedTs.status = getOpTsStatusLabel(ts.status as TimesheetStatus);
        break;
      case UserRole.OP_MANAGER:
        mappedTs.status = getOpManagerTsStatusLabel(ts.status as TimesheetStatus);
        break;
      case UserRole.DEAL_OWNER:
        mappedTs.status = getDealOwnerTsStatusLabel(ts.status as TimesheetStatus);
        break;
    }
    return mappedTs;
  });
};

export const getOpTsStatusLabel = (status: TimesheetStatus) => {
  switch (status) {
    case TimesheetStatus.REJECTED:
      return TimesheetStatusLabel.REJECTED;

    case TimesheetStatus.ISSUED:
      return TimesheetStatusLabel.ISSUED;

    case TimesheetStatus.PENDING_ACCOUNTANT:
    case TimesheetStatus.PENDING_DEAL_OWNERS:
    case TimesheetStatus.PENDING_OP_MANAGER:
      return TimesheetStatusLabel.PENDING;

    case TimesheetStatus.CLOSED:
      return TimesheetStatusLabel.CLOSED;
  }
};

export const getAccountantTsStatusLabel = (status: TimesheetStatus) => {
  switch (status) {
    case TimesheetStatus.REJECTED:
      return TimesheetStatusLabel.REJECTED;

    case TimesheetStatus.ISSUED:
      return TimesheetStatusLabel.ISSUED;

    case TimesheetStatus.PENDING_ACCOUNTANT:
      return TimesheetStatusLabel.SUBMITTED;
    case TimesheetStatus.PENDING_DEAL_OWNERS:
    case TimesheetStatus.PENDING_OP_MANAGER:
      return TimesheetStatusLabel.PENDING;

    case TimesheetStatus.CLOSED:
      return TimesheetStatusLabel.CLOSED;
  }
};

export const getOpManagerTsStatusLabel = (status: TimesheetStatus) => {
  switch (status) {
    case TimesheetStatus.REJECTED:
      return TimesheetStatusLabel.REJECTED;

    case TimesheetStatus.ISSUED:
      return TimesheetStatusLabel.ISSUED;

    case TimesheetStatus.PENDING_OP_MANAGER:
      return TimesheetStatusLabel.SUBMITTED;
    case TimesheetStatus.PENDING_DEAL_OWNERS:
    case TimesheetStatus.PENDING_ACCOUNTANT:
      return TimesheetStatusLabel.PENDING;

    case TimesheetStatus.CLOSED:
      return TimesheetStatusLabel.CLOSED;
  }
};

export const getDealOwnerTsStatusLabel = (status: TimesheetStatus) => {
  switch (status) {
    case TimesheetStatus.REJECTED:
      return TimesheetStatusLabel.REJECTED;

    case TimesheetStatus.ISSUED:
      return TimesheetStatusLabel.ISSUED;

    case TimesheetStatus.PENDING_DEAL_OWNERS:
      return TimesheetStatusLabel.SUBMITTED;
    case TimesheetStatus.PENDING_OP_MANAGER:
    case TimesheetStatus.PENDING_ACCOUNTANT:
      return TimesheetStatusLabel.PENDING;

    case TimesheetStatus.CLOSED:
      return TimesheetStatusLabel.CLOSED;
  }
};

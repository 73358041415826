import { Button, FormControlLabel, Box, useTheme, styled } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { FormField } from '../../../../components/FormField/FormField';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { Typography } from '../../../../components/Typography/Typography';
import { AvailableMonthlyTimesheet } from '../../../../types';
import { MultiSelectPeriod } from './MultiselectPeriod';
import { IExportMultiTSForm } from './ExportMultiTimesheetModal';

interface ExportTimesheetsFormProps {
  availablePeriods: AvailableMonthlyTimesheet[];
}

export const ExportTimesheetsForm = ({ availablePeriods }: ExportTimesheetsFormProps) => {
  const { colors } = useTheme();
  const { setFieldValue, values } = useFormikContext<IExportMultiTSForm>();

  const labelStyle = {
    color: colors.text.caption,
  };

  if (!availablePeriods.length) {
    return (
      <Typography
        variant='h5'
        color={colors.textAccent.disabled}
        style={{
          padding: '50px',
          textAlign: 'center',
        }}
      >
        No available timesheets to export for selected Operating Partner(s).
      </Typography>
    );
  }

  return (
    <Content>
      <FormField label='Period' labelStyle={labelStyle}>
        <MultiSelectPeriod availablePeriods={availablePeriods} />
      </FormField>
      <FormField label='Show Data By' labelStyle={labelStyle}>
        <Box display='flex' flex={1}>
          <Button
            onClick={() => {
              setFieldValue('showDataBy', 'month');
              setFieldValue('exportCombinedCSV', true);
            }}
            variant={values.showDataBy === 'month' ? 'contained' : 'outlined'}
            style={{
              flex: 1,
              borderRadius: '4px 0px 0px 4px'
            }}
          >
            By Month
          </Button>
          <Button
            onClick={() => {
              setFieldValue('showDataBy', 'week');
            }}
            variant={values.showDataBy === 'week' ? 'contained' : 'outlined'}
            style={{
              flex: 1,
              borderRadius: 0
            }}
          >
            By Week
          </Button>
          <Button
            onClick={() => {
              setFieldValue('showDataBy', 'weekday');
            }}
            variant={values.showDataBy === 'weekday' ? 'contained' : 'outlined'}
            style={{
              flex: 1,
              borderRadius: '0px 4px 4px 0px'
            }}
          >
            By Weekday
          </Button>
        </Box>
      </FormField>

      <FormField label='Export Options' labelStyle={labelStyle}>
        <CheckboxWrapper>
          <FormControlLabel
            control={
              <Field
                name='exportSeparateCSVs'
                as={Checkbox}
                type='checkbox'
                disabled={values.showDataBy === 'month'}
                checked={values.exportSeparateCSVs}
                onChange={() => {
                  setFieldValue('exportSeparateCSVs', !values.exportSeparateCSVs);
                  if (!values.exportSeparateCSVs) {
                    setFieldValue('exportCombinedCSV', false);
                  }
                }}
                sx={{
                  '&.Mui-disabled': {
                    color: colors.border.default,
                  }
                }}
              />
            }
            label='Export Separate CSVs (per OP)'
            sx={{
              '.MuiFormControlLabel-label.Mui-disabled': {
                color: colors.text.main
              }
            }}
          />
          <FormControlLabel
            control={
              <Field
                name='exportCombinedCSV'
                as={Checkbox}
                type='checkbox'
                checked={values.exportCombinedCSV}
                onChange={() => {
                  setFieldValue('exportCombinedCSV', !values.exportCombinedCSV);
                  if (!values.exportCombinedCSV) {
                    setFieldValue('exportSeparateCSVs', false);
                  }
                }}
              />
            }
            label='Export Combined CSV File'
          />
        </CheckboxWrapper>
      </FormField>
    </Content>
  );
};

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CheckboxWrapper = styled('div')`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
`;

import { Button, useTheme } from '@mui/material';
import { CSVLink } from 'react-csv';
import { useIcons } from '../../../hooks/useIcons';
import { AccountantTSView as TimesheetTab, MonthlyTimesheet } from '../../../types';
import { useTimesheetCSVTemplate } from '../hooks/useTimesheetCSVTemplate';

interface DownloadCSVLinkProps {
  selectedTab: TimesheetTab;
  timesheet: MonthlyTimesheet;
}

export const DownloadCSVLink = ({ selectedTab, timesheet }: DownloadCSVLinkProps) => {
  const { colors } = useTheme();
  const { DownLoadIcon } = useIcons();

  const CSVTitle = selectedTab === TimesheetTab.week ? 'WeekView' : 'WeekdayView';
  const CSVData = useTimesheetCSVTemplate(timesheet, selectedTab);

  return (
    <CSVLink
      data={CSVData}
      filename={`TimesheetStatus-${CSVTitle}-${timesheet.user?.name}.csv`}
      enclosingCharacter=''
      separator={','}
    >
      <Button
        variant='outlined'
        startIcon={<DownLoadIcon />}
        style={{
          backgroundColor: colors.surfaceBackground.bg1,
        }}
      >
        Export
      </Button>
    </CSVLink>
  );
};

import { ModalComponent } from '../../../components/ModalComponent/ModalComponent';
import { LoadingId, MonthlyTimesheet, TimesheetStatus } from '../../../types';

import { styled, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { Typography } from '../../../components/Typography/Typography';
import { ROUTES } from '../../../constants/routes';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useApproveMontlyTimesheet } from '../../../queries/hooks/useApproveMontlyTimesheet';
import { getMonthLabel } from '../../../utils/formatters';

type Props = {
  timesheet: MonthlyTimesheet;
  toggleOpen: () => void;
  isOpen: boolean;
};

export function ApproveTimesheetModal({ timesheet, isOpen, toggleOpen }: Props) {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();
  const timesheetName = `${getMonthLabel(timesheet.month)} ${timesheet.year}`;

  const { mutate: onApproveTimesheet, isLoading: approveInProgress } = useApproveMontlyTimesheet();

  const handleApproveTimesheet = async () => {
    startLoading(LoadingId.approveTimesheet);
    onApproveTimesheet(
      {
        id: timesheet.id,
        status: TimesheetStatus.CLOSED,
      },
      {
        onSuccess: () => {
          navigate(`/${ROUTES.DEAL_OWNER_ALL_TIMESHEETS}`);
          pushSuccessToast({
            title: 'Timesheet Approved',
            message: `${timesheet.user?.name} ${timesheetName} timesheet has been approved.`,
          });
          toggleOpen();
        },
        onError: () => {
          pushErrorToast({ message: 'Failed to approve timesheet' });
        },
        onSettled: () => {
          stopLoading(LoadingId.approveTimesheet);
        },
      }
    );
  };

  const timeLabel = `${getMonthLabel(timesheet.month)} ${timesheet.year}`;
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={toggleOpen}
      actionButtonsProps={{
        isSubmitDisabled: approveInProgress,
        onSubmit: handleApproveTimesheet,
        onCancel: toggleOpen,
        submitText: 'Approve',
        submitBtnStyle: {},
      }}
      title={`Approve Timesheet`}
      top='80px'
    >
      <InfoWrapper>
        <Typography variant='body' color={colors.text.main}>
          {`Are you sure you want to approve ${timesheet.user?.name} ${timeLabel} timesheet?`}
        </Typography>
      </InfoWrapper>
    </ModalComponent>
  );
}

const InfoWrapper = styled('div')`
  border-radius: 2px;
  display: flex;
  gap: 8px;
  margin-bottom: 14px;
`;

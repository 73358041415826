import { useMemo } from 'react';
import { MonthlyTimesheet, AccountantTSView } from '../../../types';
import { csvWeekdayTable, csvWeekTable } from '../../../utils/csvExportTimesheet';
import { getMonthLabelShort, getTodaysDate } from '../../../utils/formatters';

export const useTimesheetCSVTemplate = (
  timesheet: MonthlyTimesheet,
  selectedTab: AccountantTSView
) => {
  const csvTemplateData = useMemo(() => {
    const timesheetYear = timesheet.year;
    const headers = [
      ['OP:', timesheet.user?.name, '', '', '', '', ''],
      ['Period:', `${getMonthLabelShort(timesheet.month)} ${timesheetYear}`, '', '', '', '', ''],
      ['View:', selectedTab === AccountantTSView.week ? 'by Week' : 'by Weekday', '', '', '', '', ''],
      ['Date Generated:', getTodaysDate(), '', '', '', '', ''],
    ];

    if (selectedTab === AccountantTSView.week) {
      return [
        ...headers,
        ...csvWeekTable(timesheet),
      ];
    }
    return [...headers, [], ...csvWeekdayTable(timesheet), []];
  }, [selectedTab, timesheet]);

  return csvTemplateData;
};

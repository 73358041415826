import { useMemo } from 'react';
import { AccountantTSView, MonthlyTimesheetToExport } from '../../../types';
import {
  generateBulkCSVTemplate,
} from '../../../utils/csvExportTimesheet';

export const useSeparateCSVTemplate = (
  monthlyTS: MonthlyTimesheetToExport,
  selectedTab: AccountantTSView
) => {
  const csvTemplateData = useMemo(
    () => generateBulkCSVTemplate(monthlyTS, selectedTab),
    [selectedTab, monthlyTS]
  );

  return csvTemplateData;
};

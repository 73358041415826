import { useTheme } from '@mui/material';
import { TableV1 } from '../../../../components/Table/TableV1';

import {
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useParams } from 'react-router';
import { TableSkeletonLoader } from '../../../../components/SkeletonLoader/Table.SkeletonLoader';
import { useOpManagerSingleTableColumns } from '../../hooks/useOpManagerSingleTableColumns';
import { SingleTimesheetHeader } from './SingleTimesheetHeader';
import { useMonthlySingleTimesheet } from '../../../../queries/hooks/useMonthlySingleTimesheet';

export function OpManagerSingleTimesheetTable() {
  const { colors } = useTheme();
  const { id } = useParams();

  const { data: singleTimesheet, isLoading: dataIsLoading } = useMonthlySingleTimesheet({
    id: Number(id),
  });

  const columns = useOpManagerSingleTableColumns();

  const table = useReactTable({
    data: singleTimesheet?.timesheetRows ?? [],
    columns,
    enableSorting: false,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  if (dataIsLoading || !singleTimesheet) return <TableSkeletonLoader />;

  return (
    <>
      <SingleTimesheetHeader />
      <TableV1
        hasFooter
        table={table}
        height='auto'
        disableRowHover
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </>
  );
}
